<template>
  <div class="w1092">
    <date-filter @search="onCashListSearch"
                 @update="onChangeDate"
                 :startDate="searchDate.startDate"
                 :endDate="searchDate.endDate"/>

    <div class="board">
      <ul class="boardw first">
        <li>{{$t('front.board.checks')}}</li>
        <li>{{$t('front.board.applyDay')}}</li>
        <li>{{$t('front.board.withdrawMoney')}}</li>
        <li>{{$t('front.board.processDay')}}</li>
        <li>{{$t('front.board.processState')}}</li>
      </ul>

      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li><input type="checkbox" v-model="item.checked"/></li>
          <li>{{dateFormat(item.regDate)}}</li>
          <li>{{thousand(item.cashAmt)}}</li>
          <li>{{dateFormat(item.updDate)}}</li>
          <li :class="computedCashStatus(item.cashStatus).class">{{computedCashStatus(item.cashStatus).text}}</li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>

    <div class="boardbtn">
      <a class="grsbtn left" @click="onAllCheck('cashList')">{{allChecked ? $t('front.board.AllClear'): $t('front.board.AllSelect')}}</a>
      <a class="orsbtn left ml5" @click="onRemoveMsg">{{$t('front.board.AllDelete')}}</a>
    </div>
    <pagination :pageNum="pageInfo.page"
                :pageSize="pageInfo.count_per_list"
                :totalCount="pageInfo.tatal_list_count"
                @goToPage="onCashListSearch" />
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import Pagination from '@/components/ui/Pagination'
import { cashDelete } from '@/api/cash'
export default {
  name: 'exchangeList',
  components: { Pagination, DateFilter },
  data () {
    return {
      cashList: [],
      allChecked: false,
      cashType: 'out'
    }
  },
  created () {
    this.onCashListSearch()
  },
  methods: {
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
